<template>
  <div class="bg-white">
    <div class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
      <div class="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">

        <TabGroup :defaultIndex="4" as="div" class="flex flex-col-reverse">

        <div class="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
            <TabList  class="grid grid-cols-4 gap-6">
              <Tab v-for="image in product.images" :key="image.id" class="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50" v-slot="{ selected }">
                <span class="sr-only">
                  {{ image.name }}
                </span>
                <span class="absolute inset-0 rounded-md overflow-hidden">
                  <img :src="image.image" alt="" class="w-full h-full object-center object-cover" />
                </span>
                <span :class="[selected ? 'ring-indigo-500' : 'ring-transparent', 'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none']" aria-hidden="true" />
              </Tab>
            </TabList>
          </div>

          <TabPanels class="w-full aspect-w-1 aspect-h-1">
            <TabPanel v-for="image in product.images" :key="image.id">
              <img :src="image.image"  class="w-full h-full object-center object-cover sm:rounded-lg" />
            </TabPanel>
          </TabPanels>
        </TabGroup>

        <div class="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
          <h1 class="text-3xl font-extrabold tracking-tight text-gray-900">{{ product.name }}</h1>

          <div class="mt-3">
            <h2 class="sr-only">Product information</h2>
            <p class="text-3xl text-gray-900">${{ product.price }}</p>
          </div>


          <div class="mt-6">
            <h3 class="sr-only">Description</h3>

            <div class="text-base text-gray-700 space-y-6" v-html="product.description" />
          </div>

          <form class="mt-6">
            <div  class="mt-8">
                <div class="flex items-center justify-between">
                  <h2 class="text-sm font-medium text-gray-900">Choose Color:</h2>
                </div>

                <RadioGroup v-model="selectedColor" class="mt-2">
                  <RadioGroupLabel class="sr-only">
                    Choose a Color
                  </RadioGroupLabel>
                  <div class="grid grid-cols-3 gap-3 sm:grid-cols-6">
                    <RadioGroupOption as="template" v-for="color in product.available_colors" :key="color.name" :value="color"  v-slot="{ active, checked }">
                      <div :class="[color.name? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', checked ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50', 'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1']">
                        <RadioGroupLabel as="p">
                          {{ color.name }}
                        </RadioGroupLabel>
                      </div>
                    </RadioGroupOption>
                  </div>
                </RadioGroup>
              </div>
              <div  class="mt-8">
                <div class="flex items-center justify-between">
                  <h2 class="text-sm font-medium text-gray-900">Choose Size:</h2>
                  <button v-if="product.size_chart" type="button" @click="toggleModal" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">See sizing chart</button>
                </div>

                <teleport to="body" v-if="showModal">
                  <SizeChart>
                    <img :src="product.size_chart" class="w-full h-full object-center object-cover sm:rounded-lg" />
                  </SizeChart>
                </teleport>

                <RadioGroup v-model="selectedSize" class="mt-2">
                  <RadioGroupLabel class="sr-only">
                    Choose a size
                  </RadioGroupLabel>
                  <div class="grid grid-cols-3 gap-3 sm:grid-cols-6">
                    <RadioGroupOption as="template" v-for="size in product.available_sizes" :key="size.id" :value="size"  v-slot="{ active, checked }">
                      <div :class="[size ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', checked ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50', 'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1']">
                        <RadioGroupLabel as="p">
                          {{ size }}
                        </RadioGroupLabel>
                      </div>
                    </RadioGroupOption>
                  </div>
                </RadioGroup>
              </div>
              <div class="mt-6">
                <h3 class="text-sm font-medium text-gray-900">Quantity:</h3>
                <input class='mt-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block  sm:text-sm border-gray-300 rounded-md' type="number" min="1" v-model="quantity">
              </div>
            <div class="mt-10 flex sm:flex-col1">
              
              <button @click="addToCart()" type="submit" class="max-w-xs flex-1 bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full">Add to bag</button>
            </div>
          </form>

          <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
            <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
              <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <div v-if="show" class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div class="p-4">
                    <div class="flex items-start">
                      <div class="flex-shrink-0">
                        <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
                      </div>
                      <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p class="text-sm font-medium text-gray-900">
                          Success!
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          {{ quantity > 1 ? quantity + ' Items were added to cart.' : quantity + ' Item was added to cart.' }}
                        </p>
                      </div>
                      <div class="ml-4 flex-shrink-0 flex">
                        <button @click="show = false" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span class="sr-only">Close</span>
                          <XIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="bg-black hidden">
                      </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/vue'
import { CheckIcon, QuestionMarkCircleIcon, StarIcon, XIcon } from '@heroicons/vue/solid'
import { ShieldCheckIcon, CheckCircleIcon, HeartIcon, MinusSmIcon, PlusSmIcon } from '@heroicons/vue/outline'
import SizeChart from '../components/SizeChart.vue'

const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}


export default {
    name: 'Product',
    components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    SizeChart,
    CheckIcon,
    QuestionMarkCircleIcon,
    ShieldCheckIcon,
    StarIcon,
    XIcon,
    CheckCircleIcon,
    MinusSmIcon,
    PlusSmIcon,
  },
  
   async setup () {
     const store = useStore()
     const route = useRoute()
     const router = useRouter()

     const product = ref([])
     const quantity = ref(1)
     const show = ref(false)
     const selectedColor = ref(product.available_colors)
     const selectedSize = ref(product.available_sizes)

       const category_slug = route.params.category_slug
       const product_slug = route.params.product_slug
       
       
       let data = await axios.get(`/api/v1/products/${category_slug}/${product_slug}/`)
        .then(data => {
          product.value = data.data
          document.title = data.data.name + ' | BodiesbyRich'
          console.log(product._rawValue)
          
          
        })
        .catch(error => {
          if (error.response.status === 404) {
            router.push('/404')
          }
        })     
    
     return { product, quantity, show, selectedColor, selectedSize }
   },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    
    addToCart() {
            if (isNaN(this.quantity) || this.quantity < 1) {
                this.quantity = 1
            }
            
            const item = {
                product: this.product,
                quantity: this.quantity,
                selectedColor: this.selectedColor.name,
                selectedSize: this.selectedSize
            }
            this.show = true
            sleep(3000).then(()=> this.show = false)
            this.$store.commit('addToCart', item)
            this.$router.push('/cart')
    },
    toggleModal() {
      this.showModal = !this.showModal
    }   
  },
}
</script>