import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'

import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import Product from '../views/Product.vue'
import Category from '../views/Category.vue'
import Search from '../views/Search.vue'
import Cart from '../views/Cart.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Home | Bodies by Rich',
    },
  },
  {
    path: '/products',
    name: 'all_products',
    component: () => import('@/views/AllProducts.vue'),
    meta: {
      title: 'All Products | Bodies by Rich',
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: {
      title: 'Cart | Bodies by Rich',
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/views/Signup.vue'),
    meta: {
      title: 'Signup | Bodies by Rich',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: 'Login | Bodies by Rich',
    },
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/views/Terms.vue'),
    meta: {
      title: 'Terms & Conditions | Bodies by Rich',
    },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue'),
    meta: {
      title: 'Privacy Policy | Bodies by Rich',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue'),
    meta: {
      title: 'Contact | Bodies by Rich',
      requireLogin: true,
    },
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: () => import('@/views/MyAccount.vue'),
    meta: {
      title: 'Account | Bodies by Rich',
      requireLogin: true,
    },
  },
  {
    path: '/cart/checkout',
    name: 'Checkout',
    component: () => import('@/views/Checkout.vue'),
    meta: {
      title: 'Checkout | Bodies by Rich',
      requireLogin: true,
    },
  },
  {
    path: '/cart/success',
    name: 'Success',
    component: () => import('@/views/Success.vue'),
    meta: {
      title: 'Success | Bodies by Rich',
      requireLogin: true,
    },
  },
  {
    path: '/:category_slug',
    name: 'Category',
    component: Category,
  },
  {
    path: '/:category_slug/:product_slug',
    name: 'Product',
    component: Product,
  },
  {
    path: '/404',
    component: NotFound,
    meta: {
      title: 'NotFound | Bodies by Rich',
    },
  },

  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return (
      savedPosition ||
      new Promise((resolve) => {
        setTimeout(() => resolve({ top: 0, behavior: 'smooth' }), 100)
      })
    )
  },
})

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requireLogin) &&
    !store.state.isAuthenticated
  ) {
    next({ name: 'Login', query: { to: to.path } })
  } else {
    document.title = `${to.meta.title}`
    next()
  }
})

export default router
