<template>
  <ul role="list" class="border-t border-b border-gray-200 divide-y divide-gray-200">
            <li class="flex py-6 sm:py-10">
              <div class="flex-shrink-0">
                <img :src="item.product.get_image" :alt="item.product.name" class="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48" />
              </div>

              <div class="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                <div class="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                  <div>
                    <div class="flex justify-between">
                      <h3 class="text-sm">
                        <a :href="item.product.href" class="font-medium text-gray-700 hover:text-gray-800">
                          {{ item.product.name }}
                        </a>
                      </h3>
                    </div>
                    <div class="mt-1 flex text-sm">
                      <p class="text-gray-500">
                        {{ item.selectedColor }}
                      </p>
                      <p class="ml-4 pl-4 border-l border-gray-200 text-gray-500">
                        {{ item.selectedSize }}
                      </p>
                    </div>
                    <p class="mt-1 text-sm font-medium text-gray-900">{{ item.product.price }}</p>
                  </div>

                  <div class="mt-4 sm:mt-0 sm:pr-9">
                    {{ item.quantity }}
                    <button @click="decrementQuantity(item)" type="button" class="-m-2 p-2 mx-1 inline-flex text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Minus</span>
                        <MinusIcon class="h-5 w-5 text-blue-500" aria-hidden="true" />
                    </button>
                    <button @click="incrementQuantity(item)" type="button" class="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Minus</span>
                        <PlusIcon class="h-5 w-5 text-blue-500" aria-hidden="true" />
                    </button>

                    <div class="absolute top-0 right-0">
                      <button @click="removeFromCart(item)" type="button" class="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Remove</span>
                        <XIcon class="h-5 w-5 text-blue-500" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>

                <p class="mt-4 flex text-sm text-gray-700 space-x-2">
                  <CheckIcon  class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                  <!-- <ClockIcon v-else class="flex-shrink-0 h-5 w-5 text-gray-300" aria-hidden="true" /> -->
                  <span>In stock </span>
                </p>
              </div>
            </li>
          </ul>
</template>

<script>
import { CheckIcon, ClockIcon, XIcon, MinusIcon, PlusIcon } from '@heroicons/vue/solid'
export default {
    name: 'CartItem',
    components: { CheckIcon, ClockIcon, XIcon, MinusIcon, PlusIcon},
    props: {
        initialItem: Object
    },
    data() {
        return {
            item: this.initialItem
        }
    },
    methods: {
        getItemTotal(item) {
            return item.quantity * item.product.price
        },
        decrementQuantity(item) {
            item.quantity -= 1
            if (item.quantity === 0) {
                this.$emit('removeFromCart', item)
            }
            this.updateCart()
        },
        incrementQuantity(item) {
            item.quantity += 1
            this.updateCart()
        },
        updateCart() {
            localStorage.setItem('cart', JSON.stringify(this.$store.state.cart))
        },
        removeFromCart(item) {
            this.$emit('removeFromCart', item)
            this.updateCart()
        },
    },
}
</script>
