<template>
<div>
  <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
    <div class="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="flex items-center px-2 lg:px-0">
          <div class="flex-shrink-0">
            <router-link to="/" class="text-white"><img src="../assets/logo.png" /></router-link>
          </div>
          <div class="hidden lg:block lg:ml-6">
            <div id="nav" class="flex space-x-4">
              <router-link to="/mens">Mens</router-link>
              <router-link to="/womens">Womens</router-link>
              <router-link to="/unisex">Unisex</router-link>
            </div>
          </div>
        </div>
        <div class="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">
          <div class="max-w-lg w-full lg:max-w-xs">
            <form method="get" action="/search">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input id="search" name="query" class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-700 text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm" placeholder="Search" type="search" />
            </div>
            </form>
          </div>
        </div>
        <div class="flex lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="hidden lg:block lg:ml-4">
          <div class="flex items-center">
            <div class="ml-4 flow-root lg:ml-6">
                <router-link to="/cart" class="group -m-2 p-2 flex items-center">
                  <ShoppingBagIcon class="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  <span v-if="cartTotalLength > 0" class="ml-2 text-sm font-medium text-white group-hover:text-gray-100">{{ cartTotalLength }}</span>
                  <span class="sr-only">items in cart, view bag</span>
                </router-link>
              </div>

            <!-- Profile dropdown -->
            <Menu v-if="$store.state.isAuthenticated" as="div" class="ml-4 relative flex-shrink-0">
              <div>
                <MenuButton class="bg-gray-800 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span class="sr-only">Open user menu</span>
                  <span class="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                  <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </span>
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                    <router-link to="/my-account" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Your Profile</router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a @click="logout()" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer']">Sign out</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
            <div v-else class="flex ml-4">
              <router-link to="/login" class="-m-2 p-2 text-gray-400 hover:text-gray-500">
                <span class="sr-only">Account</span>
                  <UserIcon class="w-6 h-6" aria-hidden="true" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DisclosurePanel class="lg:hidden">
      <div id="nav" class="px-2 pt-2 pb-3 space-y-1">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <router-link to="/mens">Mens</router-link>
        <router-link to="/womens">Womens</router-link>
        <router-link to="/unisex">unisex</router-link>
      </div>
      <div v-if="$store.state.isAuthenticated" class="pt-4 pb-3 border-t border-gray-700">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <span class="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
            <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-white"></div>
            
          </div>
          <button type="button" class="ml-auto flex-shrink-0 bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            <span class="sr-only">items in cart, view bag</span>
            <router-link to="/cart" class="group -m-2 p-2 flex items-center">
              <ShoppingBagIcon class="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              <span v-if="cartTotalLength > 0" class="ml-2 text-sm font-medium text-white group-hover:text-gray-100">{{ cartTotalLength }}</span>
            </router-link>
          </button>
        </div>
        <div class="mt-3 px-2 space-y-1">
          <router-link :to="{ name: 'MyAccount'}" class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">Your Profile</router-link>
          <a @click="logout()" class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 cursor-pointer">Sign out</a>
        </div>
      </div>

      <div v-else class="pt-4 pb-3 border-t border-gray-700">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <router-link to="/login" class="-m-2 p-2 text-gray-400 hover:text-gray-500">
                <span class="sr-only">Account</span>
                  <UserIcon class="w-6 h-6" aria-hidden="true" />
              </router-link>
          </div>
          <button type="button" class="ml-auto flex-shrink-0 bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            <span class="sr-only">items in cart, view bag</span>
            <router-link to="/cart" class="group -m-2 p-2 flex items-center">
              <ShoppingBagIcon class="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              <span v-if="cartTotalLength > 0" class="ml-2 text-sm font-medium text-white group-hover:text-gray-100">{{ cartTotalLength }}</span>
            </router-link>
          </button>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>  
</div>
</template>

<script>
import axios from 'axios'
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { MenuIcon, ShoppingBagIcon, XIcon, UserIcon } from '@heroicons/vue/outline'
import { SearchIcon } from '@heroicons/vue/solid'



export default {
  name: 'Nav',
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    ShoppingBagIcon,
    SearchIcon,
    XIcon,
    UserIcon
  },
  data() {
    return {
      cart: {
        items: []
      }
    }
  },
  methods: {
    logout() {
      axios.defaults.headers.common["Authorization"] = ""
      localStorage.removeItem("token")
      localStorage.removeItem("username")
      localStorage.removeItem("userid")
      this.$store.commit('removeToken')
      this.$router.push('/')
    },
  },
  beforeCreate() {
    this.$store.commit('initializeStore')

     const token = this.$store.state.token
    if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
        axios.defaults.headers.common['Authorization'] = ""
    }
    
  },
  mounted() {
    this.cart = this.$store.state.cart
  },
  computed: {
    cartTotalLength() {
      let totalLength = 0

      for (let i = 0; i < this.cart.items.length; i++) {
        totalLength += this.cart.items[i].quantity
      }
      return totalLength
    }
  }
}
</script>



