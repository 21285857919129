<template>
 <Nav />
  <router-view v-slot="{ Component }">
    <template v-if="Component">
      <suspense>
        <template #default>
          <div>
            <component :is="Component"></component>
          </div>
        </template>
        <template #fallback>
          <Loading />
        </template>
      </suspense>
    </template>
  </router-view>
  <Foot />
</template>

<script>
import Nav from '@/components/Nav.vue'
import Foot from '@/components/Foot.vue'
import Loading from '@/components/Loading.vue'
export default {
  components: {
    Nav,
    Foot,
    Loading, 
  },
  
}
</script>


