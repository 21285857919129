<template>
<div>
  <main class="lg:relative">
      <div class="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
        <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span class="block xl:inline">BODIESBY</span>
            {{ ' ' }}
            <span class="block text-indigo-600 xl:inline">RICH</span>
          </h1>
          <p class="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            BodiesByRich is more than just a fitness name. It is a way of life. We provide services for friends, couples, and families. We focus on more than just strength. Through holistic approaches we offer strength training, massage therapy and yoga practice.

         <br /><br /> While some may train for sporting events; others may just want to look better for the beach. How about looking stunning in your wedding dress? Quality of life is what we're about and you may just want to be around a little while longer for your grandchildren. Make the switch to BodiesByRich!


          </p>
          <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <router-link to="/products" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                SHOP
              </router-link>
            </div>
            <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <router-link to="/contact" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10">
                CONTACT US
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <img class="absolute inset-0 w-full h-full object-cover" src="../assets/rich.jpg" alt="" />
      </div>
    </main>
  <div class="bg-white">
    <div class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
      <div class="md:flex md:items-center md:justify-between">
        <h2 class="text-2xl font-extrabold tracking-tight text-gray-900">Trending products</h2>
        <router-link to="/products" class="hidden text-sm font-medium text-indigo-600 hover:text-indigo-500 md:block">Shop the collection<span aria-hidden="true"> &rarr;</span></router-link>
      </div>

      <div class="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
        <div v-for="product in latestProducts" :key="product.id" class="group relative">
          <div class="w-full h-56 bg-gray-200 rounded-md overflow-hidden group-hover:opacity-75 lg:h-72 xl:h-80">
            <img :src="product.get_image" class="w-full h-full object-center object-cover" />
          </div>
          <h3 class="mt-4 text-sm text-gray-700">
            <router-link :to="product.get_absolute_url">
              <span class="absolute inset-0" />
              {{ product.name }}
            </router-link>
          </h3>
          <p class="mt-1 text-sm text-gray-500">{{ product.color }}</p>
          <p class="mt-1 text-sm font-medium text-gray-900">${{ product.price }}</p>
        </div>
      </div>

      <div class="mt-8 text-sm md:hidden">
        <router-link to="/products" class="font-medium text-indigo-600 hover:text-indigo-500">Shop the collection<span aria-hidden="true"> &rarr;</span></router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Home',
  data(){
    return { 
      latestProducts: []
    }
  },
  components: {

  },
  mounted() {
    this.getLatestProducts() 
  },
  methods: {
    async getLatestProducts() {
      this.$store.commit('setIsLoading', true)
     await axios.get('/api/v1/latest-products/')
      .then(response => {
        this.latestProducts = response.data
      })
      .catch(err => {
      
      })
      this.$store.commit('setIsLoading', false)
    } 
  }
}
</script>