<template>
  <div class="bg-white">
    <div class="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 class="text-3xl text-center font-extrabold tracking-tight text-gray-900 sm:text-4xl">Shopping Cart</h1>
      <form class="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
        <section aria-labelledby="cart-heading" class="lg:col-span-7" v-if="cartTotalLength">
          <h2 id="cart-heading" class="sr-only">Items in your shopping cart</h2>
            <CartItem
                v-for="item in cart.items"
                :key="item.product.id"
                v-bind:initialItem="item"
                v-on:removeFromCart="removeFromCart" />
        </section>
        <section v-if="cartTotalLength" aria-labelledby="summary-heading" class="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5">
          <h2 id="summary-heading" class="text-lg font-medium text-gray-900">Order summary</h2>

          <dl class="mt-6 space-y-4">
            <div class="flex items-center justify-between">
              <dt class="text-sm text-gray-600">
                Subtotal
              </dt>
              <dd class="text-sm font-medium text-gray-900">
                {{ subTotalPrice.toFixed(2)}}
              </dd>
            </div>
            <div class="border-t border-gray-200 pt-4 flex items-center justify-between">
              <dt class="flex items-center text-sm text-gray-600">
                <span>Shipping estimate</span>
                <a  class="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Learn more about how shipping is calculated</span>
                  <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />
                </a>
              </dt>
              <dd class="text-sm font-medium text-gray-900">
                $10.00
              </dd>
            </div>
            <div class="border-t border-gray-200 pt-4 flex items-center justify-between">
              <dt class="text-base font-medium text-gray-900">
                Order total
              </dt>
              <dd class="text-base font-medium text-gray-900">
                ${{ cartTotalPrice.toFixed(2)}}
              </dd>
            </div>
          </dl>

          <div class="mt-6">
            <router-link to="/cart/checkout">
            <button type="submit" class="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500">Checkout</button>
            </router-link>
          </div>
        </section>
      </form>
    </div>
    <div v-if="!cartTotalLength" class="text-center ">
      <p>You don't have any products</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { QuestionMarkCircleIcon} from '@heroicons/vue/solid'
import CartItem from '@/components/CartItem.vue'
export default {
  name: 'Cart',
  components: {
    CartItem,
    QuestionMarkCircleIcon,
  },
  data() {
        return {
            cart: {
                items: []
            }
        }
    },
    mounted() {
        this.cart = this.$store.state.cart
        console.log(this.cart)
    },
    methods: {
        removeFromCart(item) {
            this.cart.items = this.cart.items.filter(i => i.product.id !== item.product.id)
        }
    },
    computed: {
        cartTotalLength() {
            return this.cart.items.reduce((acc, curVal) => {
                return acc += curVal.quantity
            }, 0)
        },
        subTotalPrice() {
            return this.cart.items.reduce((acc, curVal) => {
                return acc += curVal.product.price * curVal.quantity
            }, 0)
        },
        cartTotalPrice() {
            return this.cart.items.reduce((acc, curVal) => {
                return acc += curVal.product.price * curVal.quantity
            }, 10)
        },
    }
}
</script>